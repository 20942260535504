import React from "react";

import "./layout.css";
import Header from "./header";
import NavigationLink from "./navigationlink";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1650,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main id="center">{children}</main>
        <footer id="footer">
          <NavigationLink to="/imprint">Imprint</NavigationLink>
          <NavigationLink to="/privacy">Privacy policy</NavigationLink>
          <br />© 2010 - {new Date().getFullYear()}, CERTAIN registry. All
          Rights Reserved.
          {` `}
        </footer>
      </div>
    </>
  );
};

export default Layout;
