/**
 * Write-only the password as cookie
 */
import React, { useState } from "react";
import ConferenceLayout from "../../../components/conference/conferencelayout";
import * as buttonStyle from "../../../components/button.module.css";
import { setSessionPassword } from "../utils/utils";

const PasswordProtect = () => {
  const [password, setPassword] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <ConferenceLayout>
      <h1>Join meeting</h1>
      <form method="post" className="form" onSubmit={onSubmit}>
        <div>
          <label htmlFor="password">Conference password</label>
          <input
            type="password"
            name="password"
            aria-label="Password"
            maxLength="255"
            size="35"
            placeholder="Please enter the conference password"
            onChange={(event) => setPassword(event.target.value)}
            required
          />
        </div>
        <div>
          <button
            type="submit"
            name="login-button"
            className={buttonStyle.button}
            style={{ maxWidth: "20rem", minWidth: "15rem" }}
          >
            Log in
          </button>
        </div>
      </form>
    </ConferenceLayout>
  );
};

export default PasswordProtect;
