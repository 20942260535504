import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import NavigationLink from "./navigationlink";
import NavigationIcon from "./navigationicon";
import * as styles from "./header.module.css";
import useDeviceDetect from "../utils/useDeviceDetect";
import BurgerMenu from "./burgermenu";

const Header = (props) => {
  const { isMobile } = useDeviceDetect();
  if (isMobile) {
    return (
      <header>
        <div className={styles.mobileMenuContainer}>
          <Link to="/" className={styles.navigationLogo}>
            <StaticImage src="../images/certain_logo.png" alt="CERTAIN logo" />
          </Link>
          <div className={styles.mobileMenuIconContainer}>
            <BurgerMenu />
            <NavigationIcon
              title="Create account"
              to="https://certain-registry.kikli.uni-heidelberg.de/RegApp/#UserRegistration"
            >
              person_add_alt_1
            </NavigationIcon>
            <NavigationIcon
              title="Login"
              to="https://certain-registry.kikli.uni-heidelberg.de/RegApp"
            >
              login
            </NavigationIcon>
            <NavigationIcon title="Support" to="/support/">
              help_outline
            </NavigationIcon>
          </div>
        </div>
      </header>
    );
  } else {
    return (
      <header>
        <div className={styles.menuContainer}>
          <Link to="/" className={styles.navigationLogo}>
            <StaticImage
              src="../images/certain_logo.png"
              width={100}
              alt="CERTAIN logo"
            />
          </Link>
          <ul className={styles.navigationItems}>
            <NavigationLink to="/research/">Research</NavigationLink>
            <NavigationLink to="/publications/">Publications</NavigationLink>
            <NavigationLink to="/centres/">Centres</NavigationLink>
            <NavigationLink to="/contact/">Contact</NavigationLink>
            <NavigationLink to="/links/">Links</NavigationLink>
            <NavigationLink to="/sponsors/">Sponsors</NavigationLink>
            <NavigationLink to="/conference/">Workshop</NavigationLink>
          </ul>
          <div className={styles.navigationIcons}>
            <NavigationIcon
              title="Create account"
              to="https://certain-registry.kikli.uni-heidelberg.de/RegApp/#UserRegistration"
            >
              person_add_alt_1
            </NavigationIcon>
            <NavigationIcon
              title="Login"
              to="https://certain-registry.kikli.uni-heidelberg.de/RegApp"
            >
              login
            </NavigationIcon>
            <NavigationIcon title="Support" to="/support/">
              help_outline
            </NavigationIcon>
          </div>
        </div>
      </header>
    );
  }
};

export default Header;
