import React from "react";
import * as styles from "./burgermenu.module.css";
import NavigationLink from "./navigationlink";

const BurgerMenu = (props) => (
  <span className={styles.burgerMenuContainer}>
    <i className="material-icons dodgerblue size-banner">menu</i>
    <ul className={styles.dropdownContent}>
      <NavigationLink to="/">Home</NavigationLink>
      <NavigationLink to="/research/">Research</NavigationLink>
      <NavigationLink to="/publications/">Publications</NavigationLink>
      <NavigationLink to="/centres/">Centres</NavigationLink>
      <NavigationLink to="/contact/">Contact</NavigationLink>
      <NavigationLink to="/links/">Links</NavigationLink>
      <NavigationLink to="/sponsors/">Sponsors</NavigationLink>
      <NavigationLink to="/conference/">Workshop</NavigationLink>
      <NavigationLink to="/conference/"> - Welcome</NavigationLink>
      <NavigationLink to="/conference/registration/">
        {" "}
        - Registration
      </NavigationLink>
      <NavigationLink to="/conference/program/"> - Program</NavigationLink>
      <NavigationLink to="/conference/location/"> - Location</NavigationLink>
      <NavigationLink to="/conference/virtualconference/">
        {" "}
        - Virtual meeting
      </NavigationLink>
      <NavigationLink to="/conference/sponsors/"> - Sponsors</NavigationLink>
      <NavigationLink to="/conference/contact/"> - Contact</NavigationLink>
    </ul>
  </span>
);

export default BurgerMenu;
