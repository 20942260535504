import React from "react";
import Layout from "../layout";
import ConferenceHeader from "./conferenceheader";

const ConferenceLayout = ({ children }) => {
  return (
    <>
      <Layout>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1100,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <ConferenceHeader />
          <main id="center">{children}</main>
        </div>
      </Layout>
    </>
  );
};

export default ConferenceLayout;
