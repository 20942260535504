import React from "react";
import NavigationLink from "../navigationlink";
import useDeviceDetect from "../../utils/useDeviceDetect";
import * as styles from "../header.module.css";

const ConferenceHeader = (props) => {
  const { isMobile } = useDeviceDetect();
  if (!isMobile) {
    return (
      <header>
        <div className={styles.menuContainer}>
          <ul className={styles.navigationItems}>
            <NavigationLink to="/conference/">Welcome</NavigationLink>
            <NavigationLink to="/conference/registration/">
              Registration
            </NavigationLink>
            <NavigationLink to="/conference/program/">Program</NavigationLink>
            <NavigationLink to="/conference/location/">Location</NavigationLink>
            <NavigationLink to="/conference/virtualconference/">
              Virtual meeting
            </NavigationLink>
            <NavigationLink to="/conference/sponsors/">Sponsors</NavigationLink>
            <NavigationLink to="/conference/contact/">Contact</NavigationLink>
          </ul>
        </div>
      </header>
    );
  } else {
    return <div />;
  }
};

export default ConferenceHeader;
