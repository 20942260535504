import React from "react";
import * as styles from "./navigationicon.module.css";

const Navigationicon = (props) => (
  <a href={props.to} className={styles.navLinkItem} title={props.title}>
    <i className="material-icons dodgerblue size-banner">{props.children}</i>
  </a>
);

export default Navigationicon;
