import React from "react";

import { Link } from "gatsby";
import * as styles from "./navigationlink.module.css";

const Navigationlink = (props) => (
  <li className={styles.navLinkItem}>
    <Link className={styles.navLink} activeClassName="active" to={props.to}>
      {props.children}
    </Link>
  </li>
);

export default Navigationlink;
